<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Batch import items from text input</h3>

	<img srcset="/docimages/batch_import_child.png 2x " class="k-help-img float-right">
	<p class="k-no-clear">The <strong>Batch import items from text input</strong> tool is a powerful and flexible way to quickly import or modify many (up to thousands) of items all at once. <b>NOTE,</b> however, that this is a fairly complex tool to use. Many Satchel users find the <span v-html="link('batch_add_csv', 'Batch import items from CSV file')"></span> tool, which also allows for batch processing items, more straightforward.</p>

	<p class="k-no-clear">To initiate the tool:
		<ol class="k-no-clear">
			<li>Click on the <strong>EDIT</strong> <nobr><v-icon small>fas fa-edit</v-icon></nobr> icon of the parent item that the imported child items will be nested under. If there are no items in the framework, click on the title of the framework located above <em>“This framework does not include any items”</em> and then the “EDIT FRAMEWORK” button. </li>
			<li>A menu will appear. Click “Create/Copy/Import” and then select <strong>“Batch import items from text input”</strong>.</li>
			<li>A dialog will appear that looks like the screenshot at right.</li>
		</ol>
	</p>

	<p>There are two “modes” for this tool, described below. For both modes, you will start by copying text out of a spreadsheet or text editor, then pasting that text into the batch import tool. The syntax for the two modes is very similar; the main difference has to do with how you specify the hierarchical structure of the items to be imported. To toggle between the two modes click the “OPTIONS” button in the Batch ImportChild Items dialog.</p>

	<img srcset="/docimages/processing_results.png 1.5x " class="k-help-img float-right">
	<p class="k-no-clear">For both modes, once you've pasted in your text click <img srcset="/docimages/process_imported_item.png 3.3x " class="k-help-img">. A new dialog titled, <strong>Processing Results</strong> will appear displaying a preview of the imported items. Item types will be italicized in brackets at the end of the full text statement. HRC will be bolded. A colon, “:” will appear at the end of the HRC and before the full text statement. Items will appear in structural hierarchy with child items nested below their parent items.</p>
	<p class="k-no-clear">If the imported code included errors, the <strong>Processing Results - Processing Log</strong> will indicate with <img srcset="/docimages/error.png 3.3x " class="k-help-img"> text and an accompanying message. Utilize the Batch Import Items Troubleshooting page.</p>

	<p>Some troubleshooting tips, applicable to either mode, are given at the bottom of this document.</p>

	<h3>“Tabs only” mode (default)</h3>

	<img srcset="/docimages/batch_import_items.png 1.5x " class="k-help-img float-right">
	<p class="k-no-clear">This mode is designed to be used with text that originates from a spreadsheet. It allows you to specify the hierarchical structure of the items according to which column in the spreadsheet each items starts in.</p>
	<p class="k-no-clear">The easiest way to understand how to format your data is to follow the “Sample import lines” in the dialog, which might be copied from a Google Sheet that looks like the image below:</p>

	<img srcset="/docimages/import_google.png" class="k-help-img block">

	<ol>
		<li>Each row in the Sheet, and therefore each line in your pasted text input, represents an item to be imported.</li>
		<li>Each row should start with the <b>item type</b> for the item (in the sample, “Course”, “Knowledge Domain”, “Standard”, and “Element”).</li>
		<li>In the cell just to the right of the item type, specify:<ol>
			<li>First the Human Readable Code, if the item has one, prefixed with a colon and followed by a space (e.g. “:S3E1”). (To place a space within the HRC, use an underscore, “_” between words. For example, HRC <strong>Practice Skill 1</strong> would be written as “:Practice_Skill_1”)</li>
			<li>Then the Full Statement of the item (e.g. “Earth and Space Science”)</li>
		</ol></li>
		<li>Since the second row in the Sheet (the “Knowledge Domain”) is indented by one column, the batch import tool will import that item as a child of the “Course” item above it.</li>
		<li>Similarly, since the “Standard” in the third row is indented one more column over, the batch import tool will import that item as a child of the “Knowledge Domain” item, and the “Element” will be imported as a child of the “Standard”.</li>
		<li>If you wanted to include another “Element” underneath S3E1, it would be included as row 5, starting in column E. And if that was followed by another “Standard” that was meant to be a sibling of the original Standard, it would be included as row 6, starting in column D. And so on.</li>
		<li>To add “Notes” to any item, add a line feed within the cell that includes the Full Statement, and then add in all capital letters with a colon followed by a space, “NOTES: ”. Add any text desired in the notes directly after the space. See the last item in cell F4 as an example. (When dealing with notes, it is usually easier to use the other “mode” described below, or the <span v-html="link('batch_add_csv', 'Batch import items from CSV file')"></span> tool.</li>
	</ol>

	<h3>“Any whitespace character” mode</h3>

	<img srcset="/docimages/batch_import_child_item.png 1.2x " class="k-help-img float-right">
	<p class="k-no-clear">This mode is designed to be used with text that is initially edited and manipulated in a text editor, such as <a href="https://code.visualstudio.com/" target="_blank">VS Code</a>. This is the most powerful and flexible way to batch import and modify items in Satchel, but some users may find it too complex for comfort.</p>

	<p class="k-no-clear">Follow the Sample import lines to understand the syntax for this mode:</p>
	<ol class="k-no-clear">
		<li>The first word is the <strong>item type</strong>>. In the first line, <strong>Course</strong> is the item type.
			<ol type="a">
				<li>To create an item type that is two or more words, substitute an underscore, “_” for a space as is shown in <strong>Knowledge_Domain</strong></li>
				<li>Some item types can be written in short-hand, for example the algorithm automatically matches “S” item type as “Standard” and “E” item type as “Expectation”.</li>
			</ol>
		</li>
		<li>After the item type, include the <strong>item level</strong> by including the appropriate number of stars, “*”. The first item should have one star with child items nested under that top level item receiving one additional star for every subsequent nested level.
			<ol type="a">
				<li>HRC is a helpful shorthand notation to refer to an item. For example, <strong>3.LS.2.1</strong> shorthand will include 3 signifies the grade, the LS for Life Science, the 2 for the second domain, and the 1 for the first standard within that domain. 
					<ol type="i">
						<li>In the Sample lines, <strong>Grade 3</strong> item has one star, “*” so it is a top-level item. The next item, <strong>Earth and Space Science</strong> has two stars, “**” and is located directly below the one star item, <strong>Grade 3</strong>. This indicates that <strong>Earth and Space Science</strong> will be nested as a child item of <strong>Grade 3</strong>.</li>
					</ol>
				</li>
				<li>To add HRC, insert a colon before the full statement. Separate the HRC and the full statement with a space.
					<ol type="i">
						<li>To add a space in the HRC, use an underscore “_”. For example, coding of “:LO_1” will result in the HRC <strong>LO 1.</strong></li>
					</ol>
				</li>
			</ol>
		</li>
		<li>After the <strong>item level,</strong> add in Human Readable Code (HRC) 
			<ol type="a">
				<li>HRC is a helpful shorthand notation to refer to an item. For example, <strong>3.LS.2.1</strong> shorthand will include 3 signifies the grade, the LS for Life Science, the 2 for the second domain, and the 1 for the first standard within that domain. 
					<ol type="i">
						<li>HRC can be helpful for readers to follow the framework organization and understand where the standard is in relation to the rest of the framework.</li>
					</ol>
				</li>
				<li>To add HRC, insert a colon before the full statement. Separate the HRC and the full statement with a space.
					<ol type="i">
						<li>To add a space in the HRC, use an underscore “_”. For example, coding of “:LO_1” will result in the HRC <strong>LO 1.</strong></li>
					</ol>
				</li>
			</ol>
		</li>
	</ol>

	<p>Additional “power options” are also available with this mode. You can also use the “Export items in Satchel text format” option to export existing framework items into the batch import format; then paste this text into a text editor; then use search-and-replace tools to make edits and additions; then re-import the items. If you’re feeling daring, e-mail <a href="mailto:support@commongoodlt.com">support@commongoodlt.com</a> to learn more, or of you wish to pay us to do all this work for you.</p>

	<h3>Batch Import Child Items Troubleshooting</h3>

	<p>Depending on the error message you encounter, follow the appropriate troubleshooting instructions below.</p>

	<p><strong>Troubleshooting/Error - Unparsed line </strong>
		<ol>
			<li>This is a syntax error. The code did not match the expected input to create an item. Most often, all items in the <strong>Processing log</strong> will contain this error as the delimiter is not set properly for the entire import.</li>
			<li>Click <img srcset="/docimages/cancel.png 3.5x " class="k-help-img"> to return to the importing <strong>Edit Document</strong> window.</li>
			<li>If the <strong>Delimiter:</strong> options are not shown, Click <img srcset="/docimages/options.png 2.3x " class="k-help-img"> to display them. </li>
			<li>Change the delimiter options to <strong>Any whitespace character</strong> for plain text importing or <strong>Tabs only</strong> for google/excel sheet importing.</li>
			<li>Click <img srcset="/docimages/process_imported_item.png 2.9x " class="k-help-img"> to re-process the import.</li>
		</ol>
	</p>

	<p><Strong>Troubleshooting/Error - Star Levels Do Not Match</Strong>
		<img srcset="/docimages/processing_results.png 1.5x " class="k-help-img float-right" >
		<ol class="k-no-clear">
			<li>This is a syntax error. The imported code did not match the expected input due to the tab spacing or number of stars mismatched. </li>
			<li>The computer expects the first level of items to have one star/no tabs, the second level to have two stars/one tab, etc. just as is shown in the Sample import lines.</li>
			<li>In these errors, what is most common is that the number of stars, or tab spacings, is one or two extra. 
				<ol type="a">
					<li>For example, one item may have three stars, “***” (or tab indentations/columns over from “A” in a spreadsheet) and a child item should have four stars, “****” (or tab indentations/columns over from “A” in a spreadsheet) but instead accidentally had five stars, “*****” (or tab indentations/columns over from “A” in a spreadsheet). This will produce the “Star Levels” do not match error.</li>
				</ol>
			</li>
			<li>Find the mismatch in stars or tab spacing and align it so that the hierarchy of stars/tab spacing follows the proper order. 

			<br>Click <img srcset="/docimages/process_imported_item.png 2.9x" class="k-help-img"> to re-process the import.</li>
		</ol>
	</p>

	<p><strong>NOTE:</strong> For all other errors or if you are unable to resolve an error, e-mail <a href="mailto:support@commongoodlt.com">support@commongoodlt.com</a>
	for further help.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	